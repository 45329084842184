import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import Cell from 'pages/Calculations/components/Accomplishment/components/Cell';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useField, useFormikContext } from 'formik';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { replaceStringFromServer } from 'utils/replaceStringFromServer';
import { ReactComponent as In } from 'assets/icons/arrowWithBorder.svg';
import { v4 as uuidv4 } from 'uuid';
import { LimitedLocal } from 'types';
import { compensationIndexes, emptyRow, translator } from './table.constants';
import { ArrowIcon, Placeholder } from './table.helper';
import { StyledTooltip } from '../../../../Calculations/components/Accomplishment/components/CellEditorSergey/CellEditor.style';
import { DialogForm } from '../parameters-dialog.types';

export const CellRenderer = (params: ICellRendererParams<LimitedLocal>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [error, setError] = React.useState('');
  const [validField, setValidField] = React.useState('');

  const {
    setFieldValue,
    getFieldProps,
    setValues,
    values,
    errors,
    setFieldTouched
  } = useFormikContext<DialogForm>();

  const [inputProps, inputMeta, inputHelper] = useField(
    `rows.${params.node.rowIndex || 0}.${params.colDef?.field}`
  );
  React.useEffect(() => {
    if (params.data?.position === 'position') {
      const target = getFieldProps<LimitedLocal>(
        `rows.${params.node.rowIndex || 0}`
      )?.value;
      if (target && target.chapter && params.node.rowIndex) {
        const calculateIndex =
          params.node.rowIndex - compensationIndexes[target.chapter];
        if (calculateIndex >= 0 && errors.rows?.[calculateIndex]) {
          const errorState = errors.rows?.[calculateIndex] as any;
          switch (params.colDef?.field) {
            // case 'values.building':
            //   inputMeta.touched && setError(errorState?.values?.building);
            //   setValidField(errorState?.values?.building);
            //   break;
            // case 'values.mounting':
            //   inputMeta.touched && setError(errorState?.values?.mounting);
            //   setValidField(errorState?.values?.mounting);
            //   break;
            // case 'values.other':
            //   inputMeta.touched && setError(errorState?.values?.other);
            //   setValidField(errorState?.values?.other);
            //   break;
            // case 'values.equipment':
            //   inputMeta.touched && setError(errorState?.values?.equipment);
            //   setValidField(errorState?.values?.equipment);
            //   break;
            // case 'values.total':
            //   inputMeta.touched && setError(errorState?.values?.total);
            //   setValidField(errorState?.values?.total);
            //   break;
            default:
              inputMeta.touched &&
                setError(errorState?.[params.colDef?.field!] || '');

              setValidField(errorState?.[params.colDef?.field!] || '');
              break;
          }
        } else {
          setError('');
          setValidField('');
        }
      }
    } else {
      setError('');
      setValidField('');
    }
  }, [
    inputProps,
    inputMeta,
    params.data?.position,
    params.node?.rowIndex,
    params.data?.type,
    values,
    getFieldProps,
    errors,
    params.colDef?.field
  ]);
  const filesList = React.useMemo(() => {
    return params.context?.estimates || [];
  }, []);
  const limitList = React.useMemo(() => {
    const list: any[] = [];
    let flag = 'empty';
    params.api.forEachNode((el) => {
      if (el.data?.id === params.data?.id) {
        flag = 'stop';
      }
      if ((el.data?.id || 0) > 0 && flag === 'empty') {
        list.push({ id: el.data?.id, label: el.data?.title });
      }
    });
    if (list.length) {
      return [...list];
    }
    return list;
  }, [params.api, params.data?.id, params.value]);
  const options = React.useMemo(() => {
    if (params.colDef?.field === 'estimates') {
      if (filesList.length === params.value?.length) {
        return 'Все сметы';
      } else {
        return (params.value || []).map((el: any) => el.label).join(';') || '';
      }
    }
    if (params.colDef?.field === 'dependencies') {
      // if (limitList.length === params.value?.length) {
      //   return params.value?.length ? 'Все лимитированные' : '';
      // } else {
      return (params.value || []).map((el: any) => el.label).join('; ') || '';
      // }
    }
    return '';
  }, [filesList.length, limitList.length, params.colDef?.field, params.value]);

  const addRow = React.useCallback(() => {
    let index: number;
    let flag = 'empty';
    params.api.forEachNode((el) => {
      const isChapter = params.data?.chapter === el.data?.chapter;
      if (el.data?.id === params.data?.id && isChapter && flag === 'empty') {
        index = el.rowIndex!;
        flag = 'find';
      } else {
        if (flag === 'find' && isChapter) {
          index += 1;
        } else if (
          typeof el.data?.id === 'number' &&
          Number(params.data?.id) > Number(el.data?.id)
        ) {
          flag = 'stop';
        }
      }
    });
    params.api.applyTransaction({
      add: [
        {
          ...{ ...emptyRow, chapter: params.data!.chapter! },
          uuid: uuidv4(),
          estimates: filesList,
          order: params.data!.chapter! * 10 + index! + 1
        }
      ],
      addIndex: index! + 1
    });
    const newList: LimitedLocal[] = [];
    params.api.forEachNode((el) => newList.push(el.data!));
    setValues({ ...values, rows: newList }, true).then((response: any) => {
      params.api.refreshCells({ force: true });
      params.api.refreshHeader();
    });
  }, [params.api, params.node.rowIndex, setValues, filesList]);

  const changeRender = React.useCallback(() => {
    const newData = {
      ...params.data,
      asRow: !getFieldProps(`rows.${params.node.rowIndex || 0}`)?.value?.asRow
    };
    setFieldValue(`rows.${params.node.rowIndex || 0}`, newData).then(() => {
      params.api.refreshCells({ force: true });
    });
    setAnchorEl(null);
  }, [
    values,
    params.data,
    params.node.rowIndex,
    params.api,
    getFieldProps,
    setFieldValue
  ]);

  const deleteRow = () => {
    setFieldTouched(`rows.${params.node.rowIndex || 0}.title`, false, true);
    const newList: LimitedLocal[] = [];
    let targetID: LimitedLocal['uuid'];
    params.api.forEachNode((el) => {
      if (el.data?.uuid === params.data?.uuid) {
        const newData = {
          ...el.data,
          isDeleted: true
        } as LimitedLocal;
        targetID = newData.uuid;
        if (el.data?.id) {
          newList.push(newData);
        }
      } else {
        newList.push({
          ...el.data!,
          ...(targetID && {
            dependencies: el.data?.dependencies?.filter(
              (item) => item.id !== targetID
            )
          }),
          ...(el.data?.order !== undefined && { order: el.data!.order - 1 })
        });
      }
    });

    setValues({
      removed: newList.filter((el) => el.isDeleted),
      rows: newList.filter((el) => !el.isDeleted)
    }).then(() => {
      params.api.refreshCells({ force: true });
      params.api.setIsExternalFilterPresent(() => false);
      params.api.onFilterChanged();
      params.api.refreshCells({ force: true });
    });
  };

  const { ConfirmDialog, openConfirm } = useConfirmDialog({
    title: (
      <span>
        Подтвердить удаление
        <br />
        лимитированной затраты?
      </span>
    ),
    body: 'Лимитированная затрата будет удалена безвозвратно.',
    handleConfirm: (confirm, fn) => {
      if (confirm) {
        fn?.();
      }
    }
  });

  const value = React.useMemo(() => {
    switch (params.colDef?.field) {
      case 'title':
        if (error) {
          return <Placeholder />;
        }
        return validField ? (
          <span style={{ color: '#9AA2B0' }}>Обязательное поле</span>
        ) : (
          params.value
        );
      case 'type':
        return translator?.[params.value as keyof typeof translator]?.ru || '';
      case 'values.building':
      case 'values.mounting':
      case 'values.equipment':
      case 'values.other':
      case 'values.total':
        // if (error) {
        //   return params.value ? (
        //     params.value + (params.data?.type === 'percent' ? '%' : '')
        //   ) : (
        //     <Placeholder />
        //   );
        // }
        return params.value && Number(params.value.replace(',', '.')) !== 0
          ? replaceStringFromServer(
              params.value?.toString()?.replace(/\s/g, '') || '',
              18
            ) + (params.data?.type === 'percent' ? '%' : '')
          : '';
      case 'dependencies':
      case 'estimates':
        if (error) {
          return <Placeholder />;
        }
        return options;
      default:
        return params.value || '';
    }
  }, [
    error,
    options,
    params.colDef?.field,
    params.data?.type,
    params.value,
    validField
  ]);

  if (params.colDef?.field === 'id') {
    if (params.data?.position === 'group') {
      return (
        <IconButton onClick={addRow}>
          <AddIcon color="primary" />
        </IconButton>
      );
    } else {
      return (
        <>
          <Menu
            onClose={() => {
              setAnchorEl(null);
            }}
            open={!!anchorEl}
            anchorEl={anchorEl}>
            <MenuItem onClick={changeRender}>
              Отображать в {params.data?.asRow ? 'столбце' : 'строке'}
            </MenuItem>
            <MenuItem
              onClick={() => openConfirm(() => deleteRow())}
              sx={{ color: 'error.main' }}>
              Удалить позицию
            </MenuItem>
          </Menu>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon color="primary" />
          </IconButton>
          <ConfirmDialog />
        </>
      );
    }
  }
  return (
    <React.Fragment>
      {params.data?.position === 'position' &&
      params.colDef?.field === 'title' ? (
        params.data?.asRow ? (
          <StyledTooltip
            PopperProps={{
              disablePortal: false
            }}
            title={'Затрата отображается в строке'}>
            <In style={{ position: 'absolute', rotate: '90deg' }} />
          </StyledTooltip>
        ) : (
          <StyledTooltip
            PopperProps={{
              disablePortal: false
            }}
            title={'Затрата отображается в столбце'}>
            <In style={{ position: 'absolute' }} />
          </StyledTooltip>
        )
      ) : null}

      <Cell
        float={params.colDef?.cellRendererParams.float}
        showTooltip={
          Array.isArray(params.value) || params.colDef?.field === 'type'
            ? false
            : !!params.value
        }
        originData={
          params.value && Array.isArray(params.value) ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              {(params.value || [])?.map((el: any) => (
                <div key={el.id}>{el.label}</div>
              ))}
            </Box>
          ) : (
            value
          )
        }
        innerStyle={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          WebkitLineClamp: 1,
          display: 'block'
        }}
        style={{
          ...(params.data?.position === 'position' &&
            params.colDef?.field === 'title' && { paddingLeft: '24px' }),
          ...(params.data?.position === 'position' &&
            Array.isArray(params.value) && { paddingRight: '24px' }),
          ...(error && {
            borderBottom: '1px solid #F46B6B'
          })
        }}>
        {value}
        <ArrowIcon {...params} />
      </Cell>
    </React.Fragment>
  );
};
