import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { FC, MouseEvent, SyntheticEvent, useCallback, useState } from 'react';
import { ActList, CalcData } from 'types';
import { ParametersDialog } from '../ParametersDialog';
import Button from '../../../../../../components/Button';
import { ReactComponent as SuccessIcon } from 'assets/icons/cloudSuccessWhite.svg';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { ImportButton } from '../../../../../Administration/AdminReferences/Prices/components/PricesReferences.style';

export type CalculationLegendProps = {
  calculationStatus: string;
  currentAct?: ActList | null;
  hideButton: boolean;
  calculation: CalcData;
  selectedTab?: number;
  openDrawer?: () => void;
  handleOpenMenuCalculation: (event: MouseEvent<HTMLButtonElement>) => void;
  handleSelectedTab?: (event: SyntheticEvent, newValue: number) => void;
};

const CalculationLegend: FC<CalculationLegendProps> = ({
  selectedTab,
  calculation,
  currentAct,
  hideButton,
  openDrawer,
  handleSelectedTab,
  handleOpenMenuCalculation
}) => {
  const [open, setOpen] = useState(false);

  const openDialogHandler = () => {
    setOpen(true);
  };

  const closeDialogHandler = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Box height={'48px'} className="CalculationLegend">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          // pb={1.5}
        >
          <Stack direction="row" flexGrow={1}>
            <Stack
              spacing={5}
              flexGrow={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              {hideButton ? (
                <span />
              ) : (
                <Tabs value={selectedTab} onChange={handleSelectedTab}>
                  <Tab label="Расчет" />
                  <Tab label="Укрупненные" />
                  <Tab label="Ресурсы" />
                  <Tab label="Выполнение" />
                </Tabs>
              )}
              {hideButton ? (
                <span />
              ) : (
                <Stack direction="row" spacing={1.5}>
                  {/* кнопка вызова дровера настройки интеграции */}
                  {!!calculation?.integrationInfo && (
                    <Button
                      disabled={!currentAct}
                      onClick={openDrawer}
                      style={{
                        padding: '8px',
                        minWidth: '40px'
                      }}>
                      <SuccessIcon />
                    </Button>
                  )}
                  <ImportButton variant={'text'} onClick={openDialogHandler}>
                    <div>
                      <FormatListBulletedIcon />
                      Параметры {selectedTab === 3 ? 'актов' : 'расчета'}
                    </div>
                  </ImportButton>
                  <Button
                    onClick={handleOpenMenuCalculation}
                    style={{
                      padding: '8px',
                      minWidth: '40px'
                    }}>
                    <MoreHorizIcon />
                  </Button>
                  {/*<StyledMenuButton variant="outlined" onClick={handleOpenMenuCalculation}>*/}
                  {/*  <MoreHorizIcon style={{ fill: '#FFF' }} />*/}
                  {/*</StyledMenuButton>*/}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <ParametersDialog
        open={open}
        onClose={closeDialogHandler}
        isExecution={selectedTab === 3}
      />
    </>
  );
};

export default CalculationLegend;
