import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem
} from '@mui/material';
import React, { FC, memo, useCallback, useContext } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  toggleButtonGroupButtons,
  toggleButtonGroupLabels
} from './ChapterItem.constants';
import { StyledInputBox } from './ChapterItem.styles';
import { CostItemProps } from './ChapterItem.types';
import {
  GetCoefficientsV3ActResponse,
  LimitedCostsV3,
  LimitedList,
  LimitedListInfo
} from 'api/params/params.types';
import {
  StyledEstimateRangeSelect,
  StyledEstimateRangeSelectPlaceholder
} from '../../../EstimateRangeSelect/EstimateRangeSelect.styles';
import { LoaderContext } from '../../../ParametersForm';
import { BaseTextField } from '../../../../../../../../../../components/BaseTextField';
import { Delete } from '@mui/icons-material';
import { ClearableTextField } from '../../../../../../../../../../components/ClearableTextField';
import { NumericFormat } from 'react-number-format';
import { ToggleButton } from '../../../../../../../../../../components/ToggleButton';
import { ToggleButtonGroup } from '../../../../../../../../../../components/ToggleButtonGroup';
import SelectorChapterEstimate from './SelectorChapterEstimate';

export const ChapterItem: FC<CostItemProps> = memo(
  ({ className, name }): JSX.Element => {
    const { control } = useFormContext<GetCoefficientsV3ActResponse>();

    const fields = useWatch({
      name: `limitedCosts.${name}`,
      control
    });

    return (
      <Box className={className}>
        <StyledInputBox>
          {fields.map((field, index) => (
            <FieldItemLimited name={name} field={field} index={index} />
          ))}
        </StyledInputBox>
      </Box>
    );
  }
);

const FieldItemLimited: React.FC<{ name: any; field: any; index: number }> = ({
  name,
  field,
  index
}) => {
  const { control, getValues, setValue } =
    useFormContext<GetCoefficientsV3ActResponse>();

  const { limitedList, isExecution } = useContext(LoaderContext);

  const handleRemove = useCallback((field: LimitedCostsV3) => {
    let copyFields: any = getValues(`limitedCosts.${name}`);

    if (!copyFields) return;
    const filterByID = (i: any) => {
      return i.limitedCost.recordID !== field?.limitedCost.recordID;
    };
    const removedArr: any[] = getValues(`removed.limitedCosts`);

    /* Получение полного списка к удалению */
    if (field?.limitedCost.recordID) {
      /* пушится новый объект и перезаписывается значение */
      const { fileID, ...rest } = field.limitedCost;
      removedArr.push({
        ...rest,
        fileIDs: field.estimates.map((_) => _.estimateID),
        delete: true
      });
      setValue(`removed.limitedCosts`, removedArr, { shouldDirty: true });
      copyFields = copyFields!.filter(filterByID);
    } else if (index !== undefined) {
      copyFields = copyFields!.filter((_: any, i: number) => i !== index);
    }

    setValue(`limitedCosts.${name}` as 'limitedCosts.8', copyFields!, {
      shouldDirty: true
    });
  }, []);

  const Field = useCallback((index: number) => {
    const options =
      limitedList.find((_) => name === _.chapter.toString()) ||
      ({} as LimitedList);
    return isExecution ? (
      <Controller
        name={`limitedCosts.${name}.${index}.limitedCost.title` as any}
        control={control}
        render={({ field: { ref, value, ...props } }) => (
          <StyledEstimateRangeSelect
            {...props}
            select
            SelectProps={{
              renderValue: () => {
                return (
                  getValues(
                    `limitedCosts.${name}.${index}.limitedCost.title`
                  ) || (
                    <StyledEstimateRangeSelectPlaceholder>
                      Выберите коэффициент
                    </StyledEstimateRangeSelectPlaceholder>
                  )
                );
              },
              MenuProps: {
                sx: {
                  maxHeight: 500
                }
              },
              displayEmpty: true,
              value: '',
              onChange: (e) => {
                const data = JSON.parse(
                  e.target.value as string
                ) as LimitedListInfo;
                if (data) {
                  const baseName = `limitedCosts.${name}.${index}.`;
                  setValue(
                    (baseName + 'limitedCost.title') as any,
                    data.title,
                    {
                      shouldDirty: true,
                      shouldValidate: true
                    }
                  );
                  setValue((baseName + 'limitedCost.type') as any, data.type, {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                  setValue(
                    (baseName + 'limitedCost.value') as any,
                    data.value,
                    {
                      shouldDirty: true,
                      shouldValidate: true
                    }
                  );
                  setValue((baseName + 'estimates') as any, data.estimateList, {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                }
              }
            }}
            onClear={() => {
              setValue(
                `limitedCosts.${name}.${index}.limitedCost.title` as any,
                '',
                {
                  shouldValidate: true,
                  shouldDirty: true
                }
              );
            }}>
            {options?.info?.length ? (
              options?.info?.map((_) => (
                <MenuItem value={JSON.stringify(_)}>
                  {_.title} ( {toggleButtonGroupLabels[_.type].toLowerCase()}{' '}
                  &rarr; {_.value?.toString().replace(/\./g, ',')} )
                </MenuItem>
              ))
            ) : (
              <MenuItem value={''} disabled>
                Нет лимитов для выбора, создайте в расчете
              </MenuItem>
            )}
          </StyledEstimateRangeSelect>
        )}
      />
    ) : (
      <Controller
        name={`limitedCosts.${name}.${index}.limitedCost.title` as any}
        control={control}
        render={({ field: { ref, onChange, ...props } }) => (
          <BaseTextField
            {...props}
            value={getValues(`limitedCosts.${name}.${index}.limitedCost.title`)}
            onChange={(e) => {
              onChange(e);
            }}
          />
        )}
      />
    );
  }, []);

  return (
    <Box display="contents">
      {Field(index)}
      <SelectorChapterEstimate index={index} name={name} field={field} />
      <Controller
        name={`limitedCosts.${name}.${index}.limitedCost.type` as any}
        control={control}
        render={({ field: { onChange, ref, ...props }, fieldState }) => (
          <FormControl error={!!fieldState.error}>
            <ToggleButtonGroup
              {...props}
              exclusive
              fullWidth
              value={getValues(
                `limitedCosts.${name}.${index}.limitedCost.type` as any
              )}
              onChange={(_, value) => value !== null && onChange(value)}>
              {toggleButtonGroupButtons.map((groupButton) => (
                <ToggleButton key={groupButton.value} value={groupButton.value}>
                  {groupButton.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            {!!fieldState.error && (
              <FormHelperText>{fieldState.error.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Controller
        name={`limitedCosts.${name}.${index}.limitedCost.value` as any}
        control={control}
        render={({
          field: { ref, onChange, value, ...props },
          fieldState: { error }
        }) => {
          const isPercent =
            useWatch({
              control,
              name: `limitedCosts.${name}.${index}.limitedCost.type` as any
            }) === 'percent';

          return (
            <NumericFormat
              {...props}
              inputRef={ref}
              customInput={ClearableTextField}
              value={
                getValues(
                  `limitedCosts.${name}.${index}.limitedCost.value` as any
                ) ?? ''
              }
              textAlign="center"
              isAllowed={(values) => {
                const { formattedValue } = values;
                return /^(-?)(\d{0,6})(?:,\d{0,15})?%?$/.test(formattedValue);
                // return floatValue < MAX_LIMIT;
              }}
              suffix={isPercent ? '%' : ''}
              decimalSeparator=","
              decimalScale={15}
              helperText={error?.message}
              error={!!error?.message}
              onChange={(value) => {
                console.log(value.target.value);
                onChange(value.target.value);
              }}
              onClear={() => onChange('')}
            />
          );
        }}
      />
      <IconButton color="warning" onClick={() => handleRemove(field)}>
        <Delete />
      </IconButton>
    </Box>
  );
};
ChapterItem.displayName = 'CostItem';
