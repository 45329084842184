import { UserShortData } from 'types';
import { OrNull } from '../../api/references/estimates/estimates.types';

export type TPragmaModules =
  | 'procurement'
  | 'dashboard'
  | 'design'
  | 'finance'
  | 'workforce'
  | 'resource_managment'
  | 'offer'
  | 'quality_control'
  | 'maintenance';

export enum EPragmaModules {
  'procurement' = 'Procurement',
  'dashboard' = 'Dashboard',
  'design' = 'Design',
  'finance' = 'Finance',
  'workforce' = 'Workforce',
  'resource_managment' = 'RM',
  'offer' = 'Estimation',
  'quality_control' = 'QC',
  'maintenance' = 'Maintenance'
}

export interface IIntegrateInfoCalc {
  module: TPragmaModules;
  projectName: OrNull<string>;
  syncedAt: string;
}

export interface CalcData {
  author: UserShortData;
  createdAt: string;
  description?: string;
  id: number;
  title: string;
  integrationInfo: OrNull<IIntegrateInfoCalc>;
  type: CalcDataType;
  updatedAt: string;
  cost: number;
  tanglModel: string | null;
}

export interface ComplicatedRowResourceLevel {
  id?: number;
  rows: ComplicatedRowResourceRow[];
  splitRows: ComplicatedRowResourceRow[];
  title?: string;
  baseTotal: number;
  currTotal: number;
}

export interface ComplicatedRowResourceRow {
  baseTotal: null | number;
  baseTotalByUnit: null | number;
  code?: null | string;
  currTotal: null | number;
  currTotalByUnit?: null | number;
  documentCode?: null | string;
  id?: null | number;
  lsrCode?: null | string;
  quantity?: null | number;
  rdStamp?: null | string;
  repeatCount: null | number;
  type: null | ComplicatedRowResourceRowTag;
  title?: null | string;
  unit?: null | string;
  weight?: null | number;
}

export interface ComplicatedPositionTreeFile {
  children: ComplicatedPositionTreeChapter[];
  id: number;
  title: string;
}

export interface ComplicatedPositionTreeChapter {
  children: ComplicatedPositionTreePosition[];
  isUnaccepted: boolean;
  title: string;
}

export interface ComplicatedPositionTreePosition {
  id: number;
  title: string;
}

export interface CalcID {
  calcID: number;
}

export interface CalcIDAct {
  calcID: number;
  actID: number;
  projectType: CalcDataType;
}

export interface GetExecutionCalculationData {
  total: ExecutionCalculationData[];
  tree: ExecutionCalculationData[];
}

export interface ExecutionCalculationData {
  parent_id?: null | number;
  chapter: string | null;
  code: string | null;
  header: string | null;
  id: number;
  subtype: 'inactive' | 'deleted' | 'dont_belong' | null;
  lsr: string | null;
  number: number | null;
  parts: PartExecution[];
  title: string | null;
  type: TypeExecutionRow;
  unit: string | null;
  children: ExecutionCalculationData[];
}

export interface Price {
  constructionEffort: number | null;
  dynamicRows: DynamicRowExecutionData[];
  equipmentPrice: number | null;
  hasIndex?: boolean;
  hasError?: boolean;
  estimatedProfit: number | null;
  hp: number | null;
  materialsPrice: number | null;
  mechanicsEffort: number | null;
  mechanicsSalary: number | null;
  mimExploitation: number | null;
  overheads: number | null;
  sp: number | null;
  totalWorkCost: number | null;
  workCostInRubles: number | null;
  workersSalary: number | null;
}

export interface PartExecution {
  base: Price;
  actID: number | null;
  curr: Price;
  endDate: string | null;
  quantity: number | null;
  startDate: string | null;
  type: TypeExecutionParts;
}

export interface DynamicRowExecutionData {
  id: number;
  value: number;
  title: string;
}

export interface ActResponse {
  actList: ActList[];
  estimateActs: GroupedAct[];
}

export interface GroupedAct {
  estimateID: null | string;
  estimateName: null | string;
  acts: ActList[];
}

export type TActStats =
  | 'NEW'
  | 'PREPARED'
  | 'COLLECTING'
  | 'CLOSED'
  | 'REOPENED';

export interface ActList {
  id: number;
  endDate: OrNull<string | Date>;
  startDate: OrNull<string | Date>;
  onDate: OrNull<string | Date>;
  status?: TActStats;
  syncedAt?: string | Date;
  responsible?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface CreateAct extends Omit<ActList, 'id'> {}

export interface CreateActRequest {
  calcID: number;
  body: CreateAct;
}

export interface EditQuantity {
  quantity: number | null;
  rowID: number;
}

export interface EditQuantityRequest {
  calcID: number;
  actID: number;
  body: EditQuantity;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface SyncActRequest extends Omit<EditQuantityRequest, 'body'> {}

export interface SyncActResponse {
  synced: number;
}

export type TypeExecutionParts =
  | 'total'
  | 'executed'
  | 'rest'
  | 'range'
  | 'act';
export type TypeExecutionRow = 'row' | 'level' | 'rest' | 'file' | 'sum';

export type ComplicatedRowResourceRowTag =
  | 'tzr'
  | 'tzm'
  | 'mim'
  | 'materials'
  | 'equipments';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface UnitTypeEnToRu extends Record<UnitTypeEn, UnitTypeRu> {}

export type CalculationStatuses =
  | 'Базисно-индексный'
  | 'Ресурсный'
  | 'Интеграция';

export type CalculationComplexTabLabels =
  | 'Базовые цены'
  | 'Коэффициенты'
  | 'Норм. Трудоемкость'
  | 'Просмотр расчета';

export type CalculationStatusesFilter = 'Все расчеты' | CalculationStatuses;

export type CalculationComlexFilter = CalculationComplexTabLabels;

export type CalculationFullStatuses =
  | 'Базисно-индексный'
  | 'Ресурсный'
  | 'Интеграция';

export type CalculationTabLabels = 'Все расчеты' | CalculationFullStatuses;

export type CalculationCardStatuses = 'Базисно-индексный' | 'Ресурсный';

export type LevelNameCurrentType =
  | 'УРОВЕНЬ 1'
  | 'УРОВЕНЬ 2'
  | 'УРОВЕНЬ 3'
  | 'УРОВЕНЬ 4';

export type LevelNameTypeEn = 'level1' | 'level2' | 'level3' | 'level4';

export type UnitTypeRu = 'шт' | 'м' | 'м2' | 'м3' | 'кг' | 'т';

export type UnitTypeEn =
  | 'count'
  | 'meter'
  | 'square_meter'
  | 'cubic_meter'
  | 'kilogram'
  | 'tonn';

export type CalcDataType =
  | 'grandSmeta'
  | 'excel'
  | 'basisManual'
  | 'resourceManual'
  | 'tangl'
  | 'handbk'
  | 'baseIndex'
  | 'resourceIndex';

/**
 * @tags Справочник - Выполнение
 */
export interface HandbookExecutionListResponse {
  total: HandbookExecutionRowPartData[];
  tree: HandbookExecutionTreeData[];
}

/**
 * @tags Справочник - Выполнение
 */
export interface HandbookExecutionRowPartData {
  actID?: number | null;
  base: HandbookCalcRowsItem;
  curr: HandbookCalcRowsItem;
  endDate?: string | null;
  quantity?: number | null;
  rowID?: number | null;
  startDate?: string | null;
  syncedAt?: string | null;
  syncedWith?: EPragmaModules;
  type: 'total' | 'executed' | 'rest' | 'act';
  tzPerUnit?: number | null;
  tzTotal?: number | null;
  tzmPerUnit?: number | null;
  tzmTotal?: number | null;
}

/**
 * @tags Справочник - Выполнение
 */
export interface HandbookExecutionTreeData {
  children: HandbookExecutionTreeData[];
  parentID: number | null;
  hasChildren: boolean;
  id: number;
  basis: OrNull<string>;
  note: OrNull<string>;
  code?: string | null;
  lvl?: number | null;
  num?: string | null;
  title?: string | null;
  unit?: string | null;
  parts: HandbookExecutionRowPartData[];
  type: 'FOLDER' | 'RATE_POSITION' | 'RATE' | 'SUM';
  resourceType: ENUMResourceType;
  qtyPerUnit: number | null;
  qtyTotal: number | null;
}

/**
 * @tags Справочник - Выполнение
 */
export type ENUMResourceType =
  | 'WORKERS'
  | 'MACHINE_OPERATORS'
  | 'MiM'
  | 'MATERIALS'
  | 'EQUIPMENT'
  | 'SERVICES';
export type ENUMResourceTypeToHuman = Record<ENUMResourceType, string>;
export const ENUMResourceTypeToHumanObject: ENUMResourceTypeToHuman = {
  EQUIPMENT: 'Оборудование',
  MACHINE_OPERATORS: 'Механизаторы',
  MATERIALS: 'Материалы',
  MiM: 'МиМ',
  SERVICES: 'Услуги',
  WORKERS: 'Рабочие'
};

/**
 * @tags Справочник - Выполнение
 */
export interface HandbookCalcRowsItem {
  costPerUnit?: number | null;
  costTotal?: number | null;
  fotPerUnit?: number | null;
  fotTotal?: number | null;
  kzPerUnit?: number | null;
  kzTotal?: number | null;
  mimPerUnit?: number | null;
  mimTotal?: number | null;
  mtrPerUnit?: number | null;
  mtrTotal?: number | null;
  total?: number | null;
  totalPerUnit?: number | null;
}
