/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import React from 'react';

type ValueOf<T> = T[keyof T];

type TStepperContext<T extends object> = {
  depth: T;
  maxDepth: T;
  setMaxDepth: (key: keyof T, value: ValueOf<T>) => void;
  toggleDepth: (key: keyof T, value: ValueOf<T>) => void;
};
// const SStepperContext = React.createContext<TStepperContext<T>>(props)
export function createStepperContext<T extends object>(
  props: TStepperContext<T>
) {
  const Context = React.createContext<TStepperContext<T>>(props);
  return Context;
}

export function useStepper<T extends object>(props: T) {
  const [state, setState] = React.useState<T>(props);

  const [maxDepth, setDepth] = React.useState<T>({} as T);

  const setMaxDepth = React.useCallback((key: keyof T, value: ValueOf<T>) => {
    setDepth((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  const toggleDepth = React.useCallback((key: keyof T, value: ValueOf<T>) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  return {
    depth: state,
    maxDepth,
    setMaxDepth,
    toggleDepth
  };
}
