import IUseSearchParam from 'hooks/useSearchParams.types';
import {
  IndexMethodSearchParamsFileID,
  IndexMethodSearchParamsMode,
  IndexMethodSearchParamsTab,
  IndexMethodSearchParamsTabValues,
  IndexMethodSearchParamsType
} from '.';

export const INDEX_METHOD_PARAM_MODE: IUseSearchParam<IndexMethodSearchParamsMode> =
  {
    key: 'mode',
    defaultValue: 'editor',
    navigateOptions: {
      replace: true
    }
  };

export const INDEX_METHOD_PARAM_TYPE: IUseSearchParam<IndexMethodSearchParamsType> =
  {
    key: 'type',
    defaultValue: 'baseIndex',
    navigateOptions: {
      replace: true
    }
  };

export const INDEX_METHOD_PARAM_TAB: IUseSearchParam<IndexMethodSearchParamsTab> =
  {
    key: 'tab',
    defaultValue: 'calculation',
    navigateOptions: {
      replace: true
    }
  };

export const INDEX_METHOD_PARAM_FILEID: IUseSearchParam<IndexMethodSearchParamsFileID> =
  {
    key: 'fileID',
    defaultValue: '0',
    navigateOptions: {
      replace: true
    }
  };

export const INDEX_METHOD_TAB_VALUES: IndexMethodSearchParamsTabValues = {
  calculation: 'Расчет',
  enlarged: 'Укрупненные',
  resource: 'Ресурсы',
  execution: 'Выполнение'
};
