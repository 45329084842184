import AutoFixHighSharpIcon from '@mui/icons-material/AutoFixHighSharp';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography
} from '@mui/material';
import { FieldForm } from 'components/FieldForm';
import { StyledTextFieldTitle } from 'components/FieldForm/styles';
import {
  EMAIL_USER_MAX_LENGTH,
  LOGIN_USER_MAX_LENGTH
} from 'components/UserManagement/UserManagement.validation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles/theme';
import { StyledPhoneFieldForm } from './ContactForm.styles';
import { ContactFormProps } from './ContactForm.types';
import { PhoneFieldForm } from 'components/PhoneFieldForm';

export const ContactForm: React.FC<ContactFormProps> = ({
  isEditUser,
  isPhoneConfirmed,
  isEmailConfirmed,
  isShowConfirmEmail,
  isShowConfirmPhone,
  handleLoginGeneration,
  handlePasswordGeneration,
  onFocusPassword,
  onBlurPassword
}) => {
  const { t } = useTranslation('user');

  return (
    <Box>
      <Stack spacing={2}>
        <label>
          <StyledTextFieldTitle mb={0.75} variant="tooltip" pl={2}>
            {t('phone')}
          </StyledTextFieldTitle>
          <StyledPhoneFieldForm
            sx={{
              '& div': {
                borderRadius: 2,
                '& .MuiFilledInput-input': {
                  padding: '10px 16px'
                }
              }
            }}
            fullWidth
            name="phone"
            variant="filled"
            size="small"
            placeholder={t('placeholders.phone')}
            disabled={isEditUser}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  {isShowConfirmPhone ? (
                    isPhoneConfirmed ? (
                      <CheckIcon color="primary" />
                    ) : (
                      <Typography variant="caption" color="error">
                        {t('notConfirmed')}
                      </Typography>
                    )
                  ) : undefined}
                </InputAdornment>
              )
            }}
          />
        </label>
        <FieldForm
          version="profile"
          type="email"
          name="email"
          title={t('email')}
          maxLength={EMAIL_USER_MAX_LENGTH}
          placeholder={t('placeholders.email')}
          disabled={isEditUser}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {isShowConfirmEmail ? (
                  isEmailConfirmed ? (
                    <CheckIcon color="primary" />
                  ) : (
                    <Typography variant="caption" color="error">
                      {t('notConfirmed')}
                    </Typography>
                  )
                ) : undefined}
              </InputAdornment>
            )
          }}
        />

        <FieldForm
          version="profile"
          name="login"
          title={t('login')}
          maxLength={LOGIN_USER_MAX_LENGTH}
          placeholder={t('placeholders.login')}
          disabled={isEditUser}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={isEditUser}
                  onClick={handleLoginGeneration}>
                  <AutoFixHighSharpIcon
                    style={{ color: theme.palette.secondary.gray }}
                  />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {isEditUser ? null : (
          <FieldForm
            onFocus={onFocusPassword}
            onBlur={onBlurPassword}
            version="profile"
            name="password"
            title={t('password')}
            placeholder={t('placeholders.password')}
            disabled={isEditUser}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={isEditUser}
                    onClick={handlePasswordGeneration}>
                    <AutoFixHighSharpIcon
                      style={{ color: theme.palette.secondary.gray }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}
      </Stack>
    </Box>
  );
};
