import { DialogForm, TParametersDialogTabs } from './parameters-dialog.types';
import { tableValidationSchema } from './table/table.validation-schema';

export const PARAMETERS_TABS: TParametersDialogTabs = [
  {
    value: 'limited_costs',
    label: 'Лимитированные затраты'
  }
];
export const setupDialogStyle = {
  slotProps: {
    root: {
      style: {
        width: '100%',
        height: '100%'
      }
    }
  },
  PaperProps: {
    sx: {
      borderRadius: '16px',
      width: '100%',
      maxWidth: '96.8%',
      height: '100%',
      maxHeight: '96.8%'
    }
  }
};
export const formikSetup = {
  enableReinitialize: true,
  validationSchema: tableValidationSchema,
  validate: (values: DialogForm) => {
    return tableValidationSchema(values).resolve({ context: values })
      .__outputType;
  },
  validateOnChange: true,
  validateOnMount: true,
  validateOnBlur: true
};
