import { useCallback, useEffect, useMemo, useState } from 'react';
import { ENUMLocalStorage, ILocalStorageValue } from '.';

type SetValue<T> = (value: T | ((val: T) => T)) => void;

type DelValue = () => void;

function isValidJson<T extends ENUMLocalStorage>(key: T, ts: string): boolean {
  try {
    const parsed = JSON.parse(ts);
    return validateLocalStorageValue(key, parsed);
  } catch (e) {
    return false;
  }
}

function validateLocalStorageValue<T extends ENUMLocalStorage>(
  key: T,
  value: unknown
): value is ILocalStorageValue[T] {
  switch (key) {
    case ENUMLocalStorage.levelsIndexMethod ||
      ENUMLocalStorage.levelsIndexMethodPreview:
      return (
        Array.isArray(value) &&
        value.every(
          (item) =>
            typeof item === 'object' &&
            item !== null &&
            'id' in item &&
            typeof item.id === 'number' &&
            'levels' in item &&
            Array.isArray(item.levels) &&
            item.levels.every((level: number) => typeof level === 'number')
        )
      );
    default:
      return true;
  }
}

export const useLocalStorage = <T extends ENUMLocalStorage>(
  key: T,
  initialValue?: ILocalStorageValue[T]
): {
  value: ILocalStorageValue[T];
  setValue: SetValue<ILocalStorageValue[T]>;
  delValue: DelValue;
  firstValue: ILocalStorageValue[T];
} => {
  const localStorageValue = useMemo(() => localStorage.getItem(key), [key]);
  const initialValueMemo = useMemo(() => {
    if (Array.isArray(initialValue) && initialValue.length === 0) {
      return undefined;
    }
    return initialValue;
  }, [initialValue]);

  const parsedValue = useMemo(
    () =>
      localStorageValue && isValidJson(key, localStorageValue)
        ? JSON.parse(localStorageValue)
        : initialValueMemo,
    [initialValueMemo, key, localStorageValue]
  );

  const [value, setValue] = useState<ILocalStorageValue[T]>(parsedValue);

  const updateValue: SetValue<ILocalStorageValue[T]> = useCallback(
    (newValue) => {
      try {
        const valueToStore =
          newValue instanceof Function ? newValue(value) : newValue;

        // Обновляем только если значение изменилось
        if (JSON.stringify(valueToStore) !== JSON.stringify(value)) {
          setValue(valueToStore);
          localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        console.warn('Error writing to localStorage:', error);
      }
    },
    [key, value]
  );

  useEffect(() => {
    const localStorageValue = localStorage.getItem(key);

    if (localStorageValue) {
      if (!isValidJson(key, localStorageValue)) {
        localStorage.setItem(key, JSON.stringify(initialValueMemo));
        setValue(initialValueMemo);
      }
    } else {
      localStorage.setItem(key, JSON.stringify(initialValueMemo));
      setValue(initialValueMemo);
    }
  }, [initialValueMemo, key]);

  const deleteValue = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setValue(undefined);
    } catch (error) {
      console.warn('Error removing from localStorage:', error);
    }
  }, [key]);

  return {
    value,
    setValue: updateValue,
    delValue: deleteValue,
    firstValue: parsedValue as unknown as ILocalStorageValue[T]
  };
};

export const getLocalStorage = <T extends ENUMLocalStorage>(
  key: T
): ILocalStorageValue[T] | null => {
  try {
    const serializedValue = localStorage.getItem(key as string);
    const result = serializedValue && JSON.parse(serializedValue);
    if (result) {
      return result as ILocalStorageValue[T];
    } else {
      return null;
    }
  } catch (error) {
    console.warn('Error reading from localStorage:', error);
    return null;
  }
};
