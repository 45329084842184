import styled from 'styled-components';

export const CustomHeaderWrapper = styled('div')<{ isOpen: boolean }>`
  svg {
    path {
      transition: 0.1s rotate linear;
      transform-origin: center center;
      rotate: ${({ isOpen }) => (isOpen ? '0deg' : '180deg')};
    }
  }
`;
