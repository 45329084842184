import { AdminProjects } from 'pages/Administration/AdminProjects';
import { ProjectManagement } from 'pages/Administration/ProjectManagement';
import Dashboard from 'pages/Dashboard';
import { Lsr } from 'pages/Lsr';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminLayout from '../layouts/AdminLayout';
import CalculationLayout from '../layouts/CalculationLayout';
import HomeLayout from '../layouts/HomeLayout';
import { Layout } from '../layouts/Layout';
import AdminUsers from '../pages/Administration/AdminUsers';
import AddUser from '../pages/Administration/AdminUsers/AddUser';
import EditUser from '../pages/Administration/AdminUsers/EditUser';
import CalculationsCard from '../pages/Calculations';
import { EditCalculationPage } from '../pages/Calculations/components/EditCalculation';
import { PriceReference } from '../pages/Administration/AdminReferences';
import TanglIntegrate from '../pages/Administration/Integrations/TanglIntegrate';
import Test from '../pages/Test';
import { IndexMethod } from 'pages/projects-calculation/index-method';

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Layout />}>
        <Route path="/*" element={<HomeLayout />}>
          <Route path="projects" element={<AdminProjects />} />
          <Route
            path="projects/:projectID/calculations"
            element={<CalculationsCard />}
          />
          <Route path="projects/:projectID/lsr" element={<Lsr />} />
          <Route path="dashboard/:projectID" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/projects" />} />
        </Route>
        <Route path="administration/*" element={<AdminLayout />}>
          <Route path="users/" element={<AdminUsers />} />
          <Route path="users/add" element={<AddUser />} />
          <Route path="users/edit/:userId" element={<EditUser />} />
          <Route path="projects" element={<AdminProjects />} />
          <Route path="projects/add" element={<ProjectManagement />} />
          <Route
            path="projects/edit/:projectId"
            element={<ProjectManagement />}
          />
          <Route path="references" element={<PriceReference />} />
          <Route path="integration" element={<TanglIntegrate />} />
          <Route path="*" element={<Navigate to="/administration/users" />} />
        </Route>
        <Route path="" element={<CalculationLayout />}>
          <Route
            path="projects/:projectID/calculation/:calcID/edit"
            element={<EditCalculationPage />}
          />
          <Route
            path="projects/:projectID/calculation/:calcID/index-method"
            element={<IndexMethod />}
          />
          <Route path="test" element={<Test />} />
        </Route>
        <Route index element={<Navigate to="/projects" />} />
      </Route>
    </Routes>
  );
};
