/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import {
  ICalculationDictionary,
  useCreateRowHandbkMutation,
  useDeleteFolderCalculationMutation,
  useDeleteRateCalculationMutation,
  useDeleteRatesWithPositionsMutation
} from 'api/calculationDictionary';
import { RowType } from 'api/references/estimates/estimates.types';
import { useCalcId } from 'hooks/useCalcId';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { enqueueSnackbar } from 'notistack';
import LevelIcon from 'pages/Administration/AdminReferences/Prices/components/LevelIcon';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { IErrorData } from '../../../../../../../Administration/AdminReferences/Prices/PricesReference.types';
import AddLevelBtn from '../../../../../../../Administration/AdminReferences/Prices/components/AddLevelBtn';
import { CalculationDirectoryContext } from '../../CalculationTab';
import { rootRow } from '../../CalculationTab.types';
import FunctionsIcon from '@mui/icons-material/Functions';

const Level: FC<ICellRendererParams<ICalculationDictionary, any, any>> = (
  props
) => {
  const { data, api } = props;
  const { t } = useTranslation('references');
  // const [deleteFolder] = useDeleteFolderMutation();
  const [deleteFolder] = useDeleteFolderCalculationMutation();
  const [deleteRate] = useDeleteRateCalculationMutation();
  const [deletePositions] = useDeleteRatesWithPositionsMutation();

  const {
    rows,
    collapse,
    removedIds,
    setSelectRow,
    selectRow,
    unCollapseRows,
    clearRemovedIDs,
    setDeletedLvlTitle,
    deleteFolderConfirm,
    checkboxClick,
    confirmCreateRate
  } = useContext(CalculationDirectoryContext);

  const calcID = useCalcId();

  const [createRow, createRowResponse] = useCreateRowHandbkMutation();

  useMutationHandlers(createRowResponse, () => {
    setTimeout(() => {
      unCollapseRows();
    }, 300);
  });

  const anchorEl = useRef<null | HTMLElement>(null);
  const handleClose = () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  };
  const [isMenuRowOpened, setMenuRowOpened] = useState(false);

  const cancelCreate = () => {
    // api.applyTransaction({ remove: [data!] });
    // dispatch(changeCreated(false));
    // dispatch(removeTemplate());
  };

  const handleOpenMenuRowClick = (event: React.MouseEvent<HTMLElement>) => {
    // const type = event.currentTarget.dataset?.type;
    // const id = event.currentTarget.dataset?.dataID;
    // if (type === 'FOLDER' || type === 'RATE') {
    //   anchorEl.current = event.currentTarget;
    //   if (event.currentTarget) setMenuRowOpened(true);
    // } else {
    // if (id && checkedRatePositions.includes(id)) {
    //   setCheckedRatePositions();
    // }
    // }
  };

  const renderIcon = useCallback(
    (rowType: RowType, id: number, parent: number | null) => {
      if (rowType !== 'FOLDER' && id < 0) {
        return <div />;
      }
      switch (rowType) {
        case 'RATE':
        case 'FOLDER':
          return null;
        default:
          return removedIds.length ? (
            data?.parentID === removedIds[0].parent ? (
              <Checkbox
                tabIndex={-1}
                checked={removedIds.findIndex((_) => _.id === id) >= 0}
                onClick={() => checkboxClick(id, rowType, parent)}
                size={'small'}
              />
            ) : (
              <span />
            )
          ) : (
            <Checkbox
              tabIndex={-1}
              checked={removedIds.findIndex((_) => _.id === id) >= 0}
              onClick={() => checkboxClick(id, rowType, parent)}
              size={'small'}
            />
          );
      }
    },
    [removedIds]
  );

  if (data?.id === -2500) {
    return (
      <Box
        display="flex"
        color="#668fd2"
        alignItems={'center'}
        pl={11}
        gap={'8px'}>
        <FunctionsIcon />

        <Typography variant="h1" color="primary.light" fontSize={'14px'}>
          Суммы
        </Typography>
      </Box>
    );
  }

  const handleRemove = () => {
    if (data!.id >= 0) {
      if (data?.rowType === 'RATE') {
        data.children?.length &&
          data?.children?.forEach((item) =>
            checkboxClick(item.id, item?.rowType, item!.parentID)
          );
        checkboxClick(data!.id, data!.rowType, data!.parentID);
        // deleteFolderConfirm(data!.lvl as number, () => {
        // setTimeout(()=>{
        // if (data?.children?.length) {
        deleteFolderConfirm(
          data!.rowType!,
          () =>
            deleteRate({ calcID, rateID: data!.id })
              .then((resp) => {
                if ('error' in resp) {
                  if (
                    'originalStatus' in resp.error &&
                    resp.error.originalStatus === 400 &&
                    'data' in resp.error
                  ) {
                    if (
                      resp.error.data ===
                      'handbk.calc.delete.rate_used_as_target_in_vor'
                    ) {
                      const message = (resp as IErrorData<string>)?.error?.data;
                      enqueueSnackbar({
                        variant: 'error',
                        message:
                          'Расценка, выбранная ключевой работой не может быть удалена.',
                        autoHideDuration: 5000
                      });
                      handleClose();
                    } else if (
                      resp.error.data ===
                      'handbk.calc.delete.rate_used_in_execution'
                    ) {
                      deleteFolderConfirm(
                        data!.rowType!,
                        () =>
                          deleteRate({
                            calcID,
                            rateID: data!.id,
                            force: true
                          }).then(() => {
                            setTimeout(() => {
                              api.refreshCells({ force: true });
                            }, 400);
                            handleClose();
                            enqueueSnackbar({
                              variant: 'success',
                              message: 'Успешно удалено'
                            });
                          }),
                        true,
                        data.children?.length || 0
                      );
                    }
                  }
                } else {
                  // dispatch(removeDataByID(data!));
                  setTimeout(() => {
                    api.refreshCells({ force: true });
                  }, 400);
                  handleClose();
                  enqueueSnackbar({
                    variant: 'success',
                    message: 'Успешно удалено'
                  });
                }
                clearRemovedIDs();
              })
              .then(() => {
                if (selectRow?.id === data?.id) {
                  setSelectRow(null);
                }
              }),
          false,
          data.children?.length || 0
        );
        // } else {
        //   deleteRate({ calcID, rateID: data!.id })
        //     .then((resp) => {
        //       if ('error' in resp) {
        //         if (
        //           'originalStatus' in resp.error &&
        //           resp.error.originalStatus === 400 &&
        //           'data' in resp.error
        //         ) {
        //           if (
        //             resp.error.data ===
        //             'handbk.calc.delete.rate_used_as_target_in_vor'
        //           ) {
        //             const message = (resp as IErrorData<string>)?.error?.data;
        //             enqueueSnackbar({
        //               variant: 'error',
        //               message:
        //                 'Расценка, выбранная ключевой работой не может быть удалена.',
        //               autoHideDuration: 5000
        //             });
        //             handleClose();
        //           } else if (
        //             resp.error.data ===
        //             'handbk.calc.delete.rate_used_in_execution'
        //           ) {
        //             deleteFolderConfirm(
        //               data!.rowType!,
        //               () =>
        //                 deleteRate({
        //                   calcID,
        //                   rateID: data!.id,
        //                   force: true
        //                 }).then(() => {
        //                   setTimeout(() => {
        //                     api.refreshCells({ force: true });
        //                   }, 400);
        //                   handleClose();
        //                   enqueueSnackbar({
        //                     variant: 'success',
        //                     message: 'Успешно удалено'
        //                   });
        //                 }),
        //               true,
        //               data.children?.length || 0
        //             );
        //           }
        //         }
        //       } else {
        //         // dispatch(removeDataByID(data!));
        //         setTimeout(() => {
        //           api.refreshCells({ force: true });
        //         }, 400);
        //         handleClose();
        //         enqueueSnackbar({
        //           variant: 'success',
        //           message: 'Успешно удалено'
        //         });
        //       }
        //       clearRemovedIDs();
        //     })
        //     .then(() => {
        //       if (selectRow?.id === data?.id) {
        //         setSelectRow(null);
        //       }
        //     });
        //   return;
        // }

        // })

        // });
      } else {
        // deleteFolderConfirm(data!.lvl as number, () => {
        setDeletedLvlTitle?.(data!.lvl!);
        deleteFolderConfirm(
          data!.rowType!,
          () =>
            deleteFolder({ folderID: data!.id!, calcID })
              .then((resp) => {
                if ('error' in resp) {
                  if (
                    'originalStatus' in resp.error &&
                    resp.error.originalStatus === 400 &&
                    'data' in resp.error
                  ) {
                    if (
                      resp.error.data ===
                      'handbk.calc.delete.rate_used_as_target_in_vor'
                    ) {
                      const message = (resp as IErrorData<string>)?.error?.data;
                      enqueueSnackbar({
                        variant: 'error',
                        message:
                          'Папка с расценкой, которая выбранная ключевой работой не может быть удалена.',
                        autoHideDuration: 5000
                      });
                    } else if (
                      resp.error.data ===
                      'handbk.calc.delete.rate_used_in_execution'
                    ) {
                      deleteFolderConfirm(
                        data!.rowType!,
                        () =>
                          deleteRate({
                            calcID,
                            rateID: data!.id,
                            force: true
                          }).then(() => {
                            setTimeout(() => {
                              api.refreshCells({ force: true });
                            }, 400);
                            enqueueSnackbar({
                              variant: 'success',
                              message: 'Успешно удалено'
                            });
                          }),
                        true
                      );
                    }
                  }
                  handleClose();
                  // console.log(resp);
                  // if (message)
                  //   enqueueSnackbar({ variant: 'error', message: t(`estimates.${message}`), autoHideDuration: 5000 });
                } else {
                  // dispatch(removeDataByID(data!));
                  setTimeout(() => {
                    api.refreshCells({ force: true });
                  }, 400);
                  handleClose();
                  enqueueSnackbar({
                    variant: 'success',
                    message: 'Успешно удалено'
                  });
                }
              })
              .then(() => {
                if (selectRow?.id === data?.id) {
                  setSelectRow(null);
                }
              })
              .catch((er) => {
                console.log('log err', er);
              }),
          false
        );
        // });
      }
    } else {
      // if (tableData[0].id < 0) {
      //   enqueueSnackbar({
      //     variant: 'error',
      //     message: (
      //       <p>
      //         Сперва вы должны создать базовую сущность <br /> Вы еще не завершили создание
      //       </p>
      //     ),
      //   });
      //   return;
      // }
      // const formState: { [key: string]: any } | undefined = getFormState?.();
      // if (!formState) return;
      // for (const [_, value] of Object.entries(formState)) {
      //   if (value) {
      //     denyCreated();
      //     return;
      //   }
      // }
      // dispatch(removeTemplate());
      // dispatch(changeCreated(false));
    }
  };
  return (
    <>
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <Box
          width={'68px'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          {data?.rowType !== 'RATE_POSITION' && !!data?.children?.length ? (
            <IconButton
              tabIndex={-1}
              style={{
                outline: 'none'
              }}
              onClick={() => {
                collapse(props);
              }}
              size="small">
              <KeyboardArrowUpIcon
                tabIndex={-1}
                sx={{
                  outline: 'none',
                  transition: 'rotate .2s linear forwards',
                  rotate: rows.includes(data?.id ?? -10) ? 0 : '180deg'
                }}
              />
            </IconButton>
          ) : null}
          {data?.rowType === 'RATE_POSITION' ? (
            <>
              <IconButton
                tabIndex={-1}
                sx={{
                  width: 34,
                  height: 34
                }}
                data-type={data?.rowType}
                data-ID={data?.id}
                onClick={handleOpenMenuRowClick}
                size="small">
                {/*<BadgeLevel>{data!.lvl}</BadgeLevel>*/}
                {renderIcon(data!.rowType, data!.id, data!.parentID)}
              </IconButton>

              <IconButton
                tabIndex={-1}
                sx={{
                  width: 34,
                  height: 34
                }}
                data-type={data?.rowType}
                data-ID={data?.id}
                onClick={() => {
                  if (data?.rowType === 'RATE_POSITION') {
                    // console.log(tableData);
                    // dispatch(
                    //   setParentLevel({
                    //     id: 5 === data?.lvl ? data!.parentID : data!.id,
                    //     template: 5,
                    //   }),
                    // );
                    // dispatch(setNeighbor(5 === data?.lvl ? data!.id : null));
                    createRow({
                      body: {
                        ...rootRow,
                        insertAfterID: data!.id,
                        lvl: data!.lvl,
                        parentID: data!.parentID,
                        rowType: 'RATE_POSITION'
                      },
                      calcID
                    });
                    // add(5, node.rowIndex, data?.lvl, data?.rowType !== 'RATE_POSITION' ? data?.id : data?.parentID);
                    // } else {
                    //   enqueueSnackbar({
                    //     variant: 'error',
                    //     message: 'Сперва вы должны создать базовую сущность\\Вы еще не завершили создание',
                    //   });
                    // }
                  }
                }}
                size="small">
                <LevelIcon levelName={5} />
              </IconButton>
            </>
          ) : (
            <>
              <div />
              <AddLevelBtn
                maxLevel={13}
                level={data?.lvl ?? null}
                data={data! as any}
                isCreated={false}
                isCalc={true}
                type={data?.rowType!}
                remove={handleRemove}
                clickAddLevel={(template: number | null, t: any) => {
                  console.log(template);
                  if (template === null) {
                    console.log('create resource');
                    if (
                      (data?.resourceType ||
                        data?.base?.fotPerUnit ||
                        data?.base?.mimPerUnit ||
                        data?.base?.mtrPerUnit ||
                        data?.workTimePerUnit ||
                        data?.workTimeTotal) &&
                      !data?.children?.length
                    ) {
                      confirmCreateRate(() => {
                        createRow({
                          body: {
                            ...rootRow,
                            insertAfterID:
                              template === data?.lvl ? data!.id : null,
                            lvl: null,
                            parentID: data!.id,
                            rowType: 'RATE_POSITION'
                          },
                          calcID
                        });
                      });
                    } else {
                      createRow({
                        body: {
                          ...rootRow,
                          insertAfterID:
                            template === data?.lvl ? data!.id : null,
                          lvl: null,
                          parentID: data!.id,
                          rowType: 'RATE_POSITION'
                        },
                        calcID
                      });
                    }
                    return;
                  }
                  if (template === -1) {
                    console.log('RATE');
                    console.log(template);
                    if (data?.rowType === 'RATE') {
                      createRow({
                        body: {
                          ...rootRow,
                          lvl: null,
                          insertAfterID: data!.id,
                          parentID: data.parentID,
                          rowType: 'RATE'
                        },
                        calcID
                      });
                      console.log(data.parentID);
                      return;
                    } else if (data?.rowType === 'RATE_POSITION') {
                      console.log(data.parentID);
                      createRow({
                        body: {
                          ...rootRow,
                          lvl: null,
                          insertAfterID: data!.id || null,
                          parentID: data.parentID,
                          rowType: 'RATE'
                        },
                        calcID
                      });
                      return;
                    } else if (data?.rowType === 'FOLDER') {
                      console.log(data.id);
                      createRow({
                        body: {
                          ...rootRow,
                          // insertAfterID: template === data?.lvl ? data!.id : null,
                          insertAfterID: null,
                          parentID: data.id,
                          rowType: 'RATE'
                        },
                        calcID
                      });
                      return;
                    }
                  }
                  if (template === -2) {
                    console.log('RATE_POSITION');
                    if (data?.rowType === 'RATE') {
                      console.log(data.parentID);
                      return;
                    } else if (data?.rowType === 'RATE_POSITION') {
                      console.log(data.parentID);
                      return;
                    }
                  }
                  if (!data!.lvl) {
                    console.log('type === FOLDER');
                    console.log(data!.id, `lvl = ${1}`);
                    createRow({
                      body: {
                        ...rootRow,
                        insertAfterID: template === data?.lvl ? data!.id : null,
                        lvl: 1,
                        parentID: data!.id,
                        rowType: 'FOLDER'
                      },
                      calcID
                    });
                    return;
                  } else if (template! > data!.lvl) {
                    console.log('type === FOLDER');
                    console.log(data!.id, `lvl = ${template}`);
                    createRow({
                      body: {
                        ...rootRow,
                        insertAfterID: template === data?.lvl ? data!.id : null,
                        lvl: template as number,
                        parentID: data!.id,
                        rowType: 'FOLDER'
                      },
                      calcID
                    });
                    return;
                  } else if (template === data!.lvl) {
                    console.log('type === FOLDER');
                    console.log(data?.parentID, `lvl = ${template}`);
                    createRow({
                      body: {
                        ...rootRow,
                        insertAfterID:
                          data?.parentID === null
                            ? null
                            : template === data?.lvl
                              ? data!.id
                              : null,
                        lvl: template as number,
                        parentID: data!.parentID,
                        rowType: 'FOLDER'
                      },
                      calcID
                    });
                    return;
                  }
                  // if (!isCreated) {
                  //   // console.log(tableData);
                  //   console.log(template, t);
                  //   dispatch(
                  //     setParentLevel({
                  //       id: template === data?.lvl ? data!.parentID : data!.id,
                  //       template: template as number,
                  //     }),
                  //   );
                  //   dispatch(setNeighbor(template === data?.lvl ? data!.id : null));
                  //   add(
                  //     template,
                  //     node.rowIndex,
                  //     data?.lvl,
                  //     data?.rowType !== 'RATE_POSITION' ? data?.id : data?.parentID,
                  //   );
                  //   // } else {
                  //   //   enqueueSnackbar({
                  //   //     variant: 'error',
                  //   //     message: 'Сперва вы должны создать базовую сущность\\Вы еще не завершили создание',
                  //   //   });
                  //   // }
                  // } else {
                  //   enqueueSnackbar({ variant: 'error', message: 'Вы еще не завершили создание' });
                  // }
                }}
              />
            </>
          )}
        </Box>
        {/*) : (*/}
        {/*  <div></div>*/}
        {/*)}*/}
        {/*{data?.rowType !== 'RATE_POSITION' && (*/}
        {/*  <AddLevelBtn*/}
        {/*    level={data?.lvl ?? null}*/}
        {/*    isCreated={!!data?.create}*/}
        {/*    type={data?.rowType!}*/}
        {/*    clickAddLevel={(template: number | string, t: any) => {*/}
        {/*      if (!isCreated) {*/}
        {/*        // console.log(tableData);*/}
        {/*        dispatch(*/}
        {/*          setParentLevel({*/}
        {/*            id: template === data?.lvl ? data!.parentID : data!.id,*/}
        {/*            template: template as number,*/}
        {/*          }),*/}
        {/*        );*/}
        {/*        dispatch(setNeighbor(template === data?.lvl ? data!.id : null));*/}
        {/*        add(template, node.rowIndex, data?.lvl, data?.parentID);*/}
        {/*        // } else {*/}
        {/*        //   enqueueSnackbar({*/}
        {/*        //     variant: 'error',*/}
        {/*        //     message: 'Сперва вы должны создать базовую сущность\\Вы еще не завершили создание',*/}
        {/*        //   });*/}
        {/*        // }*/}
        {/*      } else {*/}
        {/*        enqueueSnackbar({ variant: 'error', message: 'Вы еще не завершили создание' });*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={isMenuRowOpened}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem
          sx={{
            '&:hover': {
              color: (theme) => theme.palette.primary.main
            },
            width: 200,
            transition: 'color 0.2s linear'
          }}
          onClick={handleRemove}>
          Удалить
        </MenuItem>
      </Menu>
    </>
  );
};

export const BadgeLevel: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      textTransform={'uppercase'}
      p={'4px'}
      height={'20px'}
      borderRadius={'4px'}
      color={'white'}
      bgcolor={'#0044B4'}
      fontSize={'10px'}
      display={'flex'}
      alignItems={'center'}>
      ур {children}
    </Box>
  );
};

export default Level;
