import { Stack } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import { FileTypeChip } from 'pages/Calculations/components/FileTypeChip';
import { LSRFile, LSRFileStatus, UserShortData } from 'types';
import { getFormatDate } from 'utils/formatDate';
import { getFormatNumber } from 'utils/getFormatNumber';
import { getPersonInitials } from 'utils/getPersonInitials';
import { ColumnMenu, RowMenu, StatusSelect } from './components';

export const tableColumns: GridColDef<LSRFile>[] = [
  {
    field: 'name',
    headerName: 'Название файла',
    headerAlign: 'center',
    align: 'left',
    width: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ value, row }) => {
      return (
        <Stack
          direction="row"
          alignItems="center"
          spacing={3}
          overflow="hidden">
          <FileTypeChip type={row.type} />
          <span title={value}>{value}</span>
        </Stack>
      );
    }
  },
  {
    field: 'cost',
    headerName: 'Стоимость в рублях',
    headerAlign: 'center',
    align: 'center',
    width: 300,
    sortable: false,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      getFormatNumber(value)
  },
  {
    field: 'uploader',
    headerName: 'Загрузил',
    headerAlign: 'center',
    align: 'center',
    width: 300,
    sortable: false,
    valueFormatter: ({ value }: GridValueFormatterParams<UserShortData>) =>
      getPersonInitials({
        firstName: value.firstName ?? '',
        lastName: value.lastName ?? '',
        middleName: value?.middleName
      })
  },
  {
    field: 'size',
    headerName: 'Размер файла',
    headerAlign: 'center',
    align: 'center',
    width: 150,
    sortable: false
  },
  {
    field: 'uploadedAt',
    headerName: 'Дата и время',
    align: 'center',
    headerAlign: 'center',
    width: 200,
    sortable: false,
    valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
      getFormatDate({ date: value, withTime: true })
  },
  {
    field: 'status',
    headerName: 'Статус',
    headerAlign: 'center',
    align: 'center',
    width: 200,
    sortable: false,
    renderCell: ({
      value,
      row
    }: GridRenderCellParams<LSRFile, LSRFileStatus>) => {
      if (!value) return null;

      return <StatusSelect fileID={row.id} value={value} />;
    }
  },
  {
    field: 'menu',
    headerAlign: 'center',
    align: 'center',
    width: 60,
    sortable: false,
    renderHeader: () => <ColumnMenu />,
    renderCell: ({ row }: GridRenderCellParams<LSRFile>) => (
      <RowMenu
        fileID={row.id}
        filePath={row.filePath}
        name={row.name}
        type={row.type}
      />
    )
  }
];
