import { Box } from '@mui/material';
import React from 'react';
import { ColDef, ColGroupDef, EditableCallbackParams } from 'ag-grid-community';
import { WrapperAgGrid } from 'pages/Calculations/components/Accomplishment/Accomplishment.styles';
import { AgGridReact } from 'ag-grid-react';
import { CellRenderer } from './table.cell-renderer';
import { useFormikContext } from 'formik';
import { TableCellEditor } from './table.cell-editor';
import { EstimateInfo, LimitedLocal } from 'types';
import { DialogForm } from '../parameters-dialog.types';

const editable = (props: EditableCallbackParams) => {
  return props.data?.position === 'position';
};

const defaultColumns: (
  | ColDef<LimitedLocal, any>
  | ColGroupDef<LimitedLocal>
)[] = [
  {
    suppressMovable: true,
    field: 'id',
    headerName: '',
    width: 52,
    headerClass: 'empty'
  },
  {
    field: 'title',
    headerName: 'Наименование',
    flex: 1,
    colSpan(params) {
      return params.data?.position === 'group' ? 9 : 1;
    },
    cellEditor: 'TableCellEditor',
    cellRenderer: 'CellRenderer',
    editable: (params) => editable(params)
  },
  {
    field: 'estimates',
    headerName: 'Сметы',
    width: 147,
    cellEditor: 'TableCellEditor',
    cellRenderer: 'CellRenderer',
    editable: (params) => editable(params)
  },
  {
    field: 'dependencies',
    headerName: 'Лимитированные',
    width: 127,
    cellEditor: 'TableCellEditor',
    cellRenderer: 'CellRenderer',
    editable: (params) => editable(params)
  },
  {
    field: 'type',
    headerName: 'Тип значения',
    width: 106,
    cellEditor: 'TableCellEditor',
    cellRenderer: 'CellRenderer',
    editable: (params) => editable(params)
  },
  {
    field: 'values.building',
    headerName: 'Строительные работы',
    width: 147,
    cellClass: (params) => (params.data?.values?.total ? 'inactive-cell' : ''),
    cellRendererParams: {
      float: 'right'
    },
    cellEditor: 'TableCellEditor',
    cellRenderer: 'CellRenderer',
    editable: (params) => !params.data?.values?.total && editable(params)
  },
  {
    field: 'values.mounting',
    headerName: 'Монтажные работы',
    width: 147,
    cellClass: (params) => (params.data?.values?.total ? 'inactive-cell' : ''),
    cellRendererParams: {
      float: 'right'
    },
    cellEditor: 'TableCellEditor',
    cellRenderer: 'CellRenderer',
    editable: (params) => !params.data?.values?.total && editable(params)
  },
  {
    field: 'values.equipment',
    headerName: 'Оборудование, мебель, инвентарь',
    width: 147,
    cellClass: (params) => (params.data?.values?.total ? 'inactive-cell' : ''),
    cellRendererParams: {
      float: 'right'
    },
    cellEditor: 'TableCellEditor',
    cellRenderer: 'CellRenderer',
    editable: (params) => !params.data?.values?.total && editable(params)
  },
  {
    field: 'values.other',
    headerName: 'Прочие затраты',
    width: 147,
    cellClass: (params) => (params.data?.values?.total ? 'inactive-cell' : ''),
    cellRendererParams: {
      float: 'right'
    },
    cellEditor: 'TableCellEditor',
    cellRenderer: 'CellRenderer',
    editable: (params) => !params.data?.values?.total && editable(params)
  },
  {
    field: 'values.total',
    headerName: 'Итог',
    width: 147,
    cellClass: (params) => {
      if (params.data) {
        return params.data?.values?.building ||
          params.data?.values?.mounting ||
          params.data?.values?.other ||
          params.data?.values?.equipment
          ? 'inactive-cell'
          : '';
      }
      return '';
    },
    cellRendererParams: {
      float: 'right'
    },
    cellEditor: 'TableCellEditor',
    cellRenderer: 'CellRenderer',
    editable: (params) =>
      !params.data?.values?.building &&
      !params.data?.values?.mounting &&
      !params.data?.values?.equipment &&
      !params.data?.values?.other &&
      editable(params)
  }
];

const defaultPropsColumns: ColDef<any, any> | ColGroupDef<any> = {
  resizable: true,
  cellRenderer: 'CellRenderer',
  cellRendererParams: {
    float: 'left'
  }
};

type TProps = {
  estimates: EstimateInfo[] | undefined;
};

export const Table: React.FC<TProps> = ({ estimates }) => {
  const formik = useFormikContext<DialogForm>();
  const ref = React.useRef<AgGridReact>(null);

  const rowData = React.useMemo(() => {
    return formik.values.rows;
  }, [formik.values]);
  return (
    <Box width="100%" height="100%" p="16px 30px">
      <WrapperAgGrid
        style={{ border: '1px solid #D1D8FA', borderRadius: '16px' }}
        className="ag-theme-material reference-prices index-method-parameters-dialog">
        <AgGridReact
          ref={ref}
          singleClickEdit
          components={{
            CellRenderer,
            TableCellEditor
          }}
          context={{
            estimates: estimates?.map((el) => ({
              id: el.estimateID,
              label: el.estimateTitle,
              lsr: el.lsrCode
            }))
          }}
          onCellEditingStopped={(event) => {
            event.api.refreshCells({ force: true });
          }}
          defaultColDef={defaultPropsColumns}
          suppressCellFocus
          rowData={rowData}
          getRowId={(params) => {
            if (params.data.position === 'group') {
              return params.data?.id?.toString() || '';
            }
            return params.data.uuid?.toString() || '';
          }}
          columnDefs={defaultColumns}
          getRowClass={(params) => {
            return params.data?.position === 'group' ? 'group' : '';
          }}
        />
      </WrapperAgGrid>
    </Box>
  );
};
