/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { useSearchParams } from 'react-router-dom';
import IUseSearchParam from './useSearchParams.types';

const useSearchParam = <T extends string>({
  key,
  defaultValue,
  navigateOptions
}: IUseSearchParam<T>): [T, (value: T) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setter = (value: T) => {
    const newParams = new URLSearchParams(searchParams.toString());
    newParams.set(key, value as string);
    setSearchParams(newParams, navigateOptions);
  };

  return [(searchParams.get(key) || defaultValue || '') as T, setter];
};

export default useSearchParam;
