/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { PropsWithChildren } from 'react';
import { StyledTooltip } from '../CellEditorSergey/CellEditor.style';
import { Cell as C, CellWrapper } from './Cell.style';
import { TooltipProps } from '@mui/material';

export interface ICell extends PropsWithChildren {
  float?: 'left' | 'center' | 'right';
  click?: () => void;
  isError?: boolean;
  isNum?: boolean;
  isHide?: boolean;
  showTooltip?: boolean;
  weight?: boolean;
  color?: string;
  className?: string;
  originData?: string | number | null | React.ReactNode;
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  placement?: TooltipProps['placement'];
}

const Cell: React.FC<ICell> = ({
  weight = false,
  children,
  isHide,
  isError,
  float = 'left',
  click,
  showTooltip = true,
  originData = undefined,
  className,
  color,
  style,
  innerStyle,
  placement
}) => {
  return (
    <CellWrapper
      className={className}
      style={style}
      color={color}
      isError={isError}
      onClick={() => click?.()}>
      <StyledTooltip
        title={
          showTooltip
            ? originData !== undefined
              ? originData || ''
              : children || ''
            : ''
        }
        placement={placement}
        PopperProps={{
          disablePortal: false
        }}>
        <C
          color={color}
          isNum={false}
          float={float}
          weight={weight}
          style={innerStyle}>
          {isHide ? null : children}
        </C>
      </StyledTooltip>
    </CellWrapper>
  );
};

export default Cell;
