import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Radio, Stack, Typography } from '@mui/material';
import { ReactComponent as DateCreate } from 'assets/icons/date-created.svg';
import { ReactComponent as DateUpdate } from 'assets/icons/date-update.svg';
import { ReactComponent as MethodsIcon } from 'assets/icons/method-calc.svg';
import { ReactComponent as Worker } from 'assets/icons/person-manager.svg';
import { useCalcId } from 'hooks/useCalcId';
import { useProjectId } from 'hooks/useProjectId';
import { useSnackbar } from 'notistack';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  useEditCalculationByIdMutation,
  useGetCalculationByIdQuery
} from '../../../../api/calculations';
import Button from '../../../../components/Button';
import { Drawer } from '../../../../components/Drawer';
import { ProjectFormTextField } from '../../../../components/FieldForm/styles';
import Progress from '../../../../components/Progress';
import useConfirmDialog from '../../../../hooks/useConfirmDialog';
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers';
import { theme } from '../../../../styles/theme';
import { CalcData } from '../../../../types';
import { DATE_FORMAT } from '../../../../utils/constants';
import { formatDateToString } from '../../../../utils/formatDate';
import CalculationBasic from '../CalculationBasic';
import CalculationComplex from '../CalculationComplex';
import HandbookCalculation from '../CalculationDirectory';
import { StyledFormControlLabel } from '../CalculationDrawerForm/CalculationDrawerForm.styles';
import CalculationСomplicated from '../CalculationСomplicated';
import TanglTable from '../TanglTable';
import CopyDrawer from '../CalculationDirectory/components/CopyDrawer';

const typeProject = {
  grandSmeta: {
    method: 'Базисно-индексный метод',
    label: 'С помощью файлов xml'
  },
  excel: {
    method: 'Базисно-индексный метод',
    label: 'С помощью шаблона xls'
  },
  basisManual: {
    method: 'Базисно-индексный метод',
    label: 'В ручном режиме'
  },
  resourceManual: {
    method: 'Ресурсный метод',
    label: 'В ручном режиме'
  },
  handbk: {
    method: 'Ресурсный метод',
    label: 'По справочнику расценок'
  },
  tangl: {
    method: 'Интеграции',
    label: 'Интеграция Tangl'
  },
  baseIndex: {
    method: 'Базисно-индексный метод',
    label: 'Мега файл'
  },
  resourceIndex: {
    method: 'Ресурсно-индексный метод',
    label: 'Мега файл'
  }
};

export const EditCalculationPage: React.FC = () => {
  const calcID = useCalcId();
  const projectID = useProjectId();
  const params = new URLSearchParams(useLocation().search);
  const isPreview = params.get('state') === 'preview';
  // const edit = useLocation().state?.edit ?? true;
  const [copyDrawer, setCopyDrawer] = useState(false);
  const toggleOpenCopy = useCallback(
    (copy?: boolean) => setCopyDrawer((prevState) => (copy ? copy : prevState)),
    []
  );
  const [editCalc, setEditCalc] = useState(false);
  const { data: calculation } = useGetCalculationByIdQuery({
    projectID,
    calcID,
    isPreview
  });

  if (!isPreview) {
    return (
      <>
        <EditCalculation openEdit={(b) => setEditCalc(b)} />
        <DrawerEdit
          onClose={(b) => setEditCalc(b)}
          open={editCalc}
          calculation={calculation}
        />
      </>
    );
  } else {
    // const {
    //   data: calculation,
    //   isLoading: calculationIsLoading,
    //   isFetching: calculationIsFetching,
    // } = useGetCalculationByIdQuery({ projectID, calcID, isPreview });
    return (
      <>
        <CalculationСomplicated
          toggleOpenCopy={toggleOpenCopy}
          copyDrawer={copyDrawer}
          calculation={calculation}
          openEdit={(b) => setEditCalc(b)}
        />
        <DrawerEdit
          onClose={(b) => setEditCalc(b)}
          open={editCalc}
          calculation={calculation}
        />
      </>
    );
  }
};

export const EditCalculation: React.FC<{
  openEdit: (bool: boolean) => void;
}> = ({ openEdit }) => {
  const calcID = useCalcId();
  const projectID = useProjectId();

  const [copyDrawer, setCopyDrawer] = useState(false);

  const toggleOpenCopy = useCallback(
    (copy?: boolean) => setCopyDrawer((prevState) => (copy ? copy : prevState)),
    []
  );

  const {
    data: calculation,
    isLoading: calculationIsLoading,
    isFetching: calculationIsFetching
  } = useGetCalculationByIdQuery({ projectID, calcID });

  const isDataLoading = calculationIsLoading || calculationIsFetching;

  if (isDataLoading || !calculation) {
    return <Progress />;
  } else if (
    calculation.type === 'excel' ||
    calculation.type === 'basisManual'
  ) {
    return (
      <>
        <CalculationComplex
          copyDrawer={copyDrawer}
          toggleOpenCopy={toggleOpenCopy}
          calculation={calculation}
          openEdit={openEdit}
        />
        <CopyDrawer
          onClose={() => setCopyDrawer(false)}
          open={copyDrawer}
          calculation={calculation}
        />
      </>
    );
  } else if (calculation.type === 'resourceManual') {
    return (
      <>
        <CalculationBasic
          toggleOpenCopy={toggleOpenCopy}
          copyDrawer={copyDrawer}
          calculation={calculation}
          openEdit={openEdit}
        />
        <CopyDrawer
          onClose={() => setCopyDrawer(false)}
          open={copyDrawer}
          calculation={calculation}
        />
      </>
    );
  } else if (
    calculation.type === 'grandSmeta' ||
    calculation.type === 'baseIndex'
  ) {
    return (
      <>
        <CalculationСomplicated
          toggleOpenCopy={toggleOpenCopy}
          copyDrawer={copyDrawer}
          calculation={calculation}
          openEdit={openEdit}
        />
        <CopyDrawer
          onClose={() => setCopyDrawer(false)}
          open={copyDrawer}
          calculation={calculation}
        />
      </>
    );
  } else if (calculation.type === 'tangl') {
    return (
      <>
        <TanglTable
          toggleOpenCopy={toggleOpenCopy}
          copyDrawer={copyDrawer}
          calculation={calculation}
          openEdit={() => openEdit(true)}
        />
        <CopyDrawer
          onClose={() => setCopyDrawer(false)}
          open={copyDrawer}
          calculation={calculation}
        />
      </>
    );
  } else if (calculation.type === 'handbk') {
    return (
      <>
        <HandbookCalculation
          toggleOpenCopy={toggleOpenCopy}
          copyDrawer={copyDrawer}
          calculation={calculation}
          openEdit={() => openEdit(true)}
        />
        <CopyDrawer
          onClose={() => setCopyDrawer(false)}
          open={copyDrawer}
          calculation={calculation}
        />
      </>
    );
  } else {
    return <Progress />;
  }
};

const InformWrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: stretch;
  background: #f6f7fb;
  align-items: stretch;
`;
const InformRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #5c6e8c;
  }

  .info {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #2b3648;
  }
`;
const InformLabel = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
`;

export const DrawerEdit: React.FC<{
  open: boolean;
  onClose: (bool: boolean) => void;
  calculation: CalcData | undefined;
}> = ({ open, onClose, calculation }) => {
  const [updateCalc, updateCalcResponse] = useEditCalculationByIdMutation();

  const { enqueueSnackbar } = useSnackbar();

  const calcID = useCalcId();
  const projectID = useProjectId();

  const methods = useForm<{ title: string; description: string }>({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: {
      title: '',
      description: ''
    }
  });
  const colorTheme = theme.palette;

  const submit = (v: { title: string; description: string }) => {
    updateCalc({
      ...v,
      calcID,
      projectID
    });
  };

  const { ConfirmDialog, openConfirm } = useConfirmDialog({
    handleConfirm: (confirm) => {
      if (confirm) {
        methods.reset({
          title: '',
          description: ''
        });
        onClose(false);
      }
    }
  });

  useMutationHandlers(updateCalcResponse, () => {
    enqueueSnackbar('Успешно отредактировано', {
      variant: 'success',
      autoHideDuration: 2000
    });
    onClose(false);
  });

  useLayoutEffect(() => {
    methods.reset({
      description: calculation?.description ?? '',
      title: calculation?.title ?? ''
    });

    return () => {
      methods.reset({
        description: '',
        title: ''
      });
    };
  }, [calculation, open]);

  return (
    <Drawer
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      ModalProps={{
        keepMounted: true,
        disablePortal: true
      }}
      title={'Редактировать расчет'}
      open={open}
      onClose={() => onClose(false)}>
      <Stack
        component={'form'}
        onSubmit={methods.handleSubmit(submit)}
        flex={1}
        p={2.5}
        pb={0}
        overflow="inherit">
        <Box flex="1 1 auto">
          <Stack mb={2}>
            <Stack spacing={2}>
              <Stack spacing={2}>
                <Controller
                  rules={{
                    validate: {
                      isEmpty: (v: string) => {
                        return v.trim()
                          ? true
                          : 'Поле обязательно к заполнению';
                      }
                    }
                  }}
                  control={methods.control}
                  render={({ field, formState }) => {
                    return (
                      <ProjectFormTextField
                        sx={{
                          '.MuiFormHelperText-root': {
                            position: 'absolute',
                            bottom: -18
                          }
                        }}
                        {...field}
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                        error={!!formState.errors?.title}
                        helperText={formState.errors?.title?.message ?? ''}
                        variant="filled"
                        size="small"
                        placeholder="Укажите краткое название расчета"
                      />
                    );
                  }}
                  name={'title'}
                />
                <Controller
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <ProjectFormTextField
                        {...field}
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                        variant="filled"
                        size="small"
                        autoComplete="off"
                        multiline
                        placeholder="Укажите полное название расчета или его описание"
                        minRows={2}
                      />
                    );
                  }}
                  name={'description'}
                />
              </Stack>
              {calculation?.type && typeProject[calculation.type] && (
                <>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 500, mb: '12px' }}>
                    {typeProject[calculation.type]?.method}
                  </Typography>
                  <StyledFormControlLabel
                    disabled
                    checked={true}
                    control={
                      <Radio
                        checkedIcon={<CheckCircleIcon />}
                        icon={
                          <CheckCircleIcon
                            color="disabled"
                            style={{ color: colorTheme.bg.shades }}
                          />
                        }
                      />
                    }
                    label={typeProject[calculation.type].label}
                  />
                </>
              )}
              <InformWrapper>
                <InformRow>
                  <InformLabel>
                    <Worker />
                    <span className={'label'}>Автор:</span>
                  </InformLabel>
                  <span className={'info'}>
                    {calculation?.author?.lastName}{' '}
                    {calculation?.author?.firstName}
                  </span>
                </InformRow>
                <InformRow>
                  <InformLabel>
                    <MethodsIcon />
                    <span className={'label'}>Метод:</span>
                  </InformLabel>
                  <span className={'info'}>
                    {calculation?.type &&
                      typeProject[calculation!.type]?.method}
                  </span>
                </InformRow>
                <InformRow>
                  <span className={'label'}>Даты создания:</span>
                  <span />
                </InformRow>
                <InformRow>
                  <InformLabel>
                    <DateCreate />
                    <span className={'label'}>Дата создания:</span>
                  </InformLabel>
                  <span className={'info'}>
                    {calculation?.createdAt &&
                      formatDateToString(
                        new Date(calculation!.createdAt),
                        DATE_FORMAT
                      )}
                  </span>
                </InformRow>
                <InformRow>
                  <InformLabel>
                    <DateUpdate />
                    <span className={'label'}>Дата обновления:</span>
                  </InformLabel>
                  <span className={'info'}>
                    {calculation?.updatedAt &&
                      formatDateToString(
                        new Date(calculation!.updatedAt),
                        DATE_FORMAT
                      )}
                  </span>
                </InformRow>
              </InformWrapper>
            </Stack>
          </Stack>
        </Box>
        <Box
          pb={'20px'}
          display={'grid'}
          alignItems={'center'}
          gridTemplateColumns={'1fr 1fr'}
          gap={'10px'}>
          <Button type="submit" color={'success'}>
            Сохранить
          </Button>
          <Button
            onClick={() => {
              methods.formState.isDirty ? openConfirm() : onClose(false);
            }}>
            Отменить
          </Button>
        </Box>
      </Stack>
      <ConfirmDialog />
    </Drawer>
  );
};
