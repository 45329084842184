import { CalendarToday } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useField } from 'formik';
import React, { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import {
  FormFieldProps,
  SELECT_ITEM_HEIGHT,
  SELECT_ITEM_PADDING_TOP,
  SELECT_ITEMS_RENDER_COUNT
} from './FieldForm.types';
import {
  DatePickerRenderInput,
  ProjectFormTextField,
  SmallFormTextField,
  StyledModalTextField,
  StyledSelectField,
  StyledSelectFieldModal,
  StyledTextField,
  StyledTextFieldTitle
} from './styles';

export const FieldForm = forwardRef<HTMLDivElement, FormFieldProps>(
  (
    {
      version,
      title,
      unit,
      price,
      quantity,
      minLength,
      maxLength,
      fieldProps,
      onBlur,
      dataValue,
      onDataChange,
      children,
      isTooltip,
      dateFieldProps,
      className,
      InputProps,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [{ onBlur: onFieldBlur, ...field }, { touched, error }] = useField({
      name: props.name,
      ...fieldProps
    });

    const handleBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        onFieldBlur?.(e);
        onBlur?.(e);
      },
      [onBlur, onFieldBlur]
    );

    const isError = touched && !!error;
    if (props.name === 'shortName') {
      // console.log('log is error', isError, touched, error);
    }
    switch (version) {
      case 'profile':
        return (
          <label style={{ textAlign: 'left' }}>
            <StyledTextFieldTitle mb={0.75} variant="tooltip" pl={2}>
              {title}
            </StyledTextFieldTitle>
            <StyledTextField
              {...field}
              InputProps={{ disableUnderline: true }}
              fullWidth
              variant="filled"
              size="small"
              helperText={
                isError ? t(error as string, { maxLength }) : undefined
              }
              onBlur={handleBlur}
              error={isError}
              {...props}
            />
          </label>
        );
      case 'modal':
        return (
          <label style={{ textAlign: 'left' }}>
            <StyledTextFieldTitle
              mb={0.75}
              variant="tooltip"
              pl={2}
              style={{ color: '#5c6e8c', fontWeight: 400 }}>
              {title}
            </StyledTextFieldTitle>
            <Tooltip title={props.value as string} open={isTooltip}>
              <StyledModalTextField
                {...field}
                InputProps={{ disableUnderline: true }}
                fullWidth
                variant="filled"
                size="small"
                helperText={
                  isError ? t(error as string, { maxLength }) : undefined
                }
                onBlur={handleBlur}
                error={isError}
                {...props}
                autoComplete="off"
              />
            </Tooltip>
          </label>
        );
      case 'selectModal':
        return (
          <label style={{ textAlign: 'left' }}>
            <StyledTextFieldTitle
              mb={0.75}
              variant="tooltip"
              pl={2}
              style={{ color: '#5c6e8c', fontWeight: 400 }}>
              {title}
            </StyledTextFieldTitle>

            <StyledSelectFieldModal
              select
              {...field}
              InputProps={{ disableUnderline: true }}
              helperText={
                isError ? t(error as string, { maxLength }) : undefined
              }
              onBlur={handleBlur}
              error={isError}
              {...props}>
              {children}
            </StyledSelectFieldModal>
          </label>
        );
      case 'project':
      case 'calculation':
      case 'rd':
      case 'objects':
      case 'doc':
        return (
          <ProjectFormTextField
            {...field}
            InputProps={{ ...InputProps, disableUnderline: true }}
            inputProps={{ ...props.inputProps, maxLength, minLength }}
            fullWidth
            variant="filled"
            size="small"
            helperText={isError ? t(error as string, { maxLength }) : undefined}
            onBlur={handleBlur}
            error={isError}
            className={className}
            autoComplete="off"
            {...props}
          />
        );

      case 'date':
        return (
          <DesktopDatePicker
            mask="__.__.____"
            value={dataValue}
            onChange={onDataChange!}
            {...dateFieldProps}
            components={{
              OpenPickerIcon: CalendarToday
            }}
            renderInput={(params) => (
              <DatePickerRenderInput
                helperText={
                  isError ? t(error as string, { maxLength }) : undefined
                }
                {...props}
                {...params}
                error={isError}
                inputProps={{
                  ...params.inputProps,
                  placeholder:
                    props.placeholder || 'Нажмите, чтобы добавить дату',
                  maxLength,
                  minLength,
                  autoComplete: 'off'
                }}
              />
            )}
            className={className}
          />
        );

      case 'select':
        return (
          <StyledSelectField
            select
            {...field}
            InputProps={{ ...InputProps, disableUnderline: true }}
            helperText={isError ? t(error as string, { maxLength }) : undefined}
            inputProps={{ ...props.inputProps, maxLength, minLength }}
            onBlur={handleBlur}
            error={isError}
            className={className}
            {...props}
            SelectProps={{
              ...props.SelectProps,
              MenuProps: {
                ...props.SelectProps?.MenuProps,
                PaperProps: {
                  ...props.SelectProps?.MenuProps?.PaperProps,
                  sx: {
                    maxHeight:
                      SELECT_ITEM_HEIGHT * SELECT_ITEMS_RENDER_COUNT +
                      SELECT_ITEM_PADDING_TOP,

                    '& .MuiMenuItem-root .MuiTypography-root': {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    },
                    ...props.SelectProps?.MenuProps?.PaperProps?.sx
                  }
                }
              }
            }}>
            {children}
          </StyledSelectField>
        );

      case 'small':
        return (
          <SmallFormTextField
            {...field}
            InputProps={{ disableUnderline: true }}
            fullWidth
            variant="filled"
            size="small"
            helperText={isError ? t(error as string, { maxLength }) : undefined}
            onBlur={handleBlur}
            error={isError}
            {...props}
          />
        );

      default:
        return (
          <TextField
            {...field}
            onBlur={handleBlur}
            error={isError}
            helperText={isError ? t(error as string) : undefined}
            className={className}
            {...props}
          />
        );
    }
  }
);

FieldForm.displayName = 'FieldForm';
