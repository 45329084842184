import styled from 'styled-components';

export const PageStyled = styled('div')<{ margin?: boolean }>`
  //display: flex;
  //flex-direction: column;
  //flex-grow: 1;
  width: 100%;
  ${({ margin }) => !margin && `margin-top: 10px;`} // margin-top: 10px;
    background-color: #fff;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: auto;
  display: grid;
  height: 100%;
  grid-template-rows: min-content 1fr;
`;

export const LevelResource = styled('div')<{
  type: 'sum' | 'file' | 'otherFile' | 'row' | 'level';
}>`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  padding-left: ${({ type }) =>
    type === 'level'
      ? 52
      : type === 'file' || type === 'sum' || type === 'otherFile'
        ? 20
        : 0}px;
  ${({ type }) =>
    type === 'sum' &&
    'font-weight: 700 !important; font-size: 16px; line-height: 150%; color: rgb(81, 114, 161);'}
`;

export const DividerStyled = styled('div')`
  height: 4px;
  background-color: rgba(25, 118, 210, 0.08);
`;
