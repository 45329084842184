export enum ENUMLocalStorage {
  levelsIndexMethod = 'levelsIndexMethod',
  levelsIndexMethodPreview = 'levelsIndexMethodPreview'
}

export type LSlevelsIndexMethodProps =
  | { id: number; levels: number[] }[]
  | undefined;
export interface ILocalStorageValue {
  [ENUMLocalStorage.levelsIndexMethod]: LSlevelsIndexMethodProps;
  [ENUMLocalStorage.levelsIndexMethodPreview]: LSlevelsIndexMethodProps;
  [key: string]: unknown;
}
