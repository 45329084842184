import styled from 'styled-components';

export const IndexMethodWrapper = styled.div<{ $isGrid: boolean }>`
  width: 100%;
  // margin-top: 10px;
  // background-color: #fff;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: auto;
  ${({ $isGrid }) => ($isGrid ? 'display: grid;' : '')}
  height: 100%;
`;
IndexMethodWrapper.defaultProps = {
  $isGrid: true
};
