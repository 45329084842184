import { Box } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  grid-template-rows: min-content min-content 1fr;
`;
export const Header = styled(Box)`
  padding: 14px 30px;
  display: flex;
  border-bottom: 1px solid #d1d8fa;
  align-items: center;
  justify-content: space-between;
`;
export const BtnWrapper = styled(Box)`
  width: 372px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
`;
