import React, { FC, useEffect } from 'react';
import {
  IndexMethodHeader,
  IndexMethodProps,
  IndexMethodWrapper,
  useIndexMethodRest
} from '.';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { IndexMethodCalculation } from 'pages/projects-calculation/index-method-calculation';
import { VorPage } from 'pages/Vor';
import Resources from 'pages/Calculations/components/Resources';
import { createStepperContext, useStepper } from 'hooks/useStepper';

type Stepper = {
  indexPage: number;
};
const defaultStateStepper: Stepper = {
  indexPage: 1
};
export const StepperIndexMethodContext = createStepperContext<Stepper>({
  depth: defaultStateStepper,
  maxDepth: defaultStateStepper,
  setMaxDepth: () => {
    return;
  },
  toggleDepth: () => {
    return;
  }
});

/**
 * @description
 * Это РИМ (можно и БИМ сюда бахнуть)
 */
export const IndexMethod: FC<IndexMethodProps> = () => {
  const { calcID, fileID, projectID, mode, calculation, tab, setTab } =
    useIndexMethodRest();

  const [firstLoadCalc, setFirstLoadCalc] = React.useState(true);

  useEffect(() => {
    if (tab !== 'calculation') {
      setFirstLoadCalc(true);
    }
  }, [tab]);

  const toggleFirstLoad = React.useCallback((f?: boolean) => {
    setFirstLoadCalc((prevState) => (f !== undefined ? f : !prevState));
  }, []);

  const stepperProps = useStepper<Stepper>(defaultStateStepper);

  useBreadcrumbs(
    [
      { title: `Расчеты`, url: `/projects/${projectID}/calculations` },
      {
        title:
          mode === 'viewer' ? 'Предпросмотр' : `${calculation?.title ?? ''}`
      }
    ],
    [calculation, projectID, mode, tab],
    tab === 'enlarged'
  );

  return (
    <StepperIndexMethodContext.Provider value={stepperProps}>
      {mode === 'editor' && (
        <IndexMethodHeader
          projectID={projectID}
          calcID={calcID}
          dataCalculation={calculation}
          tab={tab}
          setTab={setTab}
        />
      )}
      <IndexMethodWrapper $isGrid={tab !== 'enlarged'}>
        {tab === 'calculation' ? (
          <IndexMethodCalculation
            firstLoad={firstLoadCalc}
            toggleFirstLoad={toggleFirstLoad}
            projectID={projectID}
            calcID={calcID}
            fileID={fileID}
            mode={mode}
          />
        ) : tab === 'enlarged' ? (
          <VorPage />
        ) : tab === 'resource' ? (
          <div style={{ paddingTop: '10px' }}>
            <Resources margin={true} />
          </div>
        ) : null}
      </IndexMethodWrapper>
    </StepperIndexMethodContext.Provider>
  );
};
