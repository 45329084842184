/**
 * @author Mr_fabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { useState } from 'react';
import { Close, HeadAct, WrapperDialog } from './ExportDialog.style';
import { Dialog, MenuItem } from '@mui/material';
import { StyledSelect } from '../CaptionTable/CaptionTable.style';
import Button from '../../../../../../components/Button';
import { BtnWrapper, WarningText } from '../ActDialog/ActDialog.style';

const ExportDialog: React.FC<{ open: string; close: () => void }> = ({
  open,
  close
}) => {
  const [range, setRange] = useState('Выберите период для формирования');

  return (
    <Dialog open={!!open}>
      <WrapperDialog>
        <HeadAct>
          Экспортировать {open}
          <Close onClick={close} />
        </HeadAct>
        <WarningText>Для формирования КС-2 выберите период</WarningText>
        <div>
          <StyledSelect
            width={340}
            onClear={() => {
              alert('clear');
            }}
            nullableValues={[range]}
            SelectProps={{
              renderValue: () => range,
              MenuProps: {
                sx: {
                  maxHeight: 500,
                  width: 340
                }
              },
              value: range,
              placeholder: 'Выберите период для формирования',
              onChange: (e) => {
                setRange(e.target.value as string);
              }
            }}
            value={range}
            placeholder={'Выберите период для формирования'}
            fullWidth
            select>
            <MenuItem
              style={{ height: 0, overflow: 'hidden', padding: 0 }}
              selected
              hidden={true}
              value={'Выберите диапазон'}>
              Выберите диапазон
            </MenuItem>
            <MenuItem value={'21.08.2023 — 21.09.2023'}>
              21.08.2023 — 21.09.2023
            </MenuItem>
            <MenuItem value={'01.10.2023 — 30.10.2023'}>
              01.10.2023 — 30.10.2023
            </MenuItem>
            <MenuItem value={'01.11.2023 — 30.11.2023'}>
              01.11.2023 — 30.11.2023{' '}
            </MenuItem>
            <MenuItem value={'01.12.2023 — 30.12.2023'}>
              01.12.2023 — 30.12.2023{' '}
            </MenuItem>
            <MenuItem value={'21.08.2023 — 21.09.2023'}>
              21.08.2023 — 21.09.2023
            </MenuItem>
          </StyledSelect>
        </div>
        <BtnWrapper>
          <Button color={'success'}>Сохранить</Button>
          <Button color={'primary'} onClick={close}>
            Отменить
          </Button>
        </BtnWrapper>
      </WrapperDialog>
    </Dialog>
  );
};

export default ExportDialog;
