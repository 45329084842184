import { LimitedLocal } from 'types';

export const stateDefaultRow: Record<number, Array<LimitedLocal>> = {
  8: [
    {
      id: -8,
      chapter: 8,
      position: 'group',
      title: 'Глава 8. Временные здания и сооружения',
      estimates: [],
      dependencies: []
    }
  ],
  9: [
    {
      id: -9,
      chapter: 9,
      position: 'group',
      title: 'Глава 9. Прочие работы и затраты',
      estimates: [],
      dependencies: []
    }
  ],
  10: [
    {
      id: -10,
      chapter: 10,
      position: 'group',
      title: 'Глава 10. Содержание службы заказчика. Строительный контроль',
      estimates: [],
      dependencies: []
    }
  ],
  11: [
    {
      id: -11,
      chapter: 11,
      position: 'group',
      title:
        'Глава 11. Подготовка эксплуатационных кадров для строящегося объекта капитального строительства',
      estimates: [],
      dependencies: []
    }
  ],
  12: [
    {
      id: -12,
      chapter: 12,
      position: 'group',
      title:
        'Глава 12. Публичный технологический и ценовой аудит, подготовка обоснования инвестиций',
      estimates: [],
      dependencies: []
    }
  ],
  13: [
    {
      id: -13,
      chapter: 13,
      position: 'group',
      title: 'Непредвиденные затраты',
      estimates: [],
      dependencies: []
    }
  ],
  14: [
    {
      id: -14,
      chapter: 14,
      position: 'group',
      title: 'Дополнительные работы и затраты',
      estimates: [],
      dependencies: []
    }
  ],
  15: [
    {
      id: -15,
      chapter: 15,
      position: 'group',
      title: ' Налоги и обязательные платежи',
      estimates: [],
      dependencies: []
    }
  ]
};

export const emptyRow: LimitedLocal = {
  position: 'position',
  estimates: [],
  dependencies: [],
  type: 'percent',
  title: '',
  values: {
    building: '',
    equipment: '',
    other: '',
    mounting: '',
    total: ''
  },
  asRow: false
};
export const translator = {
  percent: {
    ru: '%',
    eng: 'percent'
  },
  coeff: {
    ru: 'К-т',
    eng: 'coeff'
  },
  sum: {
    ru: 'Сумма',
    eng: 'sum'
  }
};
export const typeLimit = [
  { id: translator.percent.eng, label: translator.percent.ru },
  { id: translator.coeff.eng, label: translator.coeff.ru },
  { id: translator.sum.eng, label: translator.sum.ru }
];
export const compensationIndexes: Record<number, number> = {
  8: 1,
  9: 2,
  10: 3,
  11: 4,
  12: 5,
  13: 6,
  14: 7,
  15: 8
};
export const innerCellStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  WebkitLineClamp: 1,
  display: 'block'
};
